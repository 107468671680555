.field-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

}


.field-wrapper form {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 10px;

}

.checkboxClassCustom .form-check {
    margin-top: 15px;
}

.button-right {
    float: right;
}

.form-check input[type="checkbox"] {

    padding: 0;
    margin-right: 10px;

}

.add-new-field {
    background-color: #f2f6fc;
    border: .1875rem dashed #d4dae3;
    border-radius: .5rem;
    text-align: center;
    padding: 10px;
    cursor: pointer;
    font-size: medium;
}

/* global css for overwrite existing css starts here */

.customTableClass td {
    padding: 0.3rem 0.5rem;
    color: #0F172A;
}

.customTableClass th {
    color: #4b4b4b;
    padding: 0.6rem 0.5rem 0.4rem;
}

.customTableClass .badge {
    padding: 8px;
}

.customTableClass thead th .btn.btn-link i {
    font-size: 22px;
    line-height: 1;
}

.customTableClass thead th .btn.btn-link {
    padding: 0;
    line-height: 1;
}

.pending_badge {
    background-color: rgb(255 237 78 / 50%);
    color: #7A4A30;
}

.closed_badge {
    background-color: rgb(255 89 89 / 20%);
    color: #AA0505;
}

.assigned_badge {
    background-color: rgb(114 124 245 / 20%);
    color: #2E3BD7;
}

.delivered_badge {
    background-color: #e4e4e4;
    color: #494949;
}

.open_badge {
    background-color: rgb(14 201 112 / 20%);
    color: #36852c;
}

.action-icon i {
    color: #0F172A;
}

.customPagination {
    width: 100%;
    margin: 10px 0 5px;
}

.customPagination label,
.customPagination span,
.customPagination a.page-link {
    color: #0F172A;
}

.customFormOuter {
    width: 100%;
    margin: 0 auto;
}

.customFormOuter .card-body {
    padding: 1rem;
}

.customFormOuter .form-control {
    margin-bottom: 15px;
}

.customFormOuter .table td .form-control {
    margin-bottom: 0px;
}

.customFormOuter .table td,
.customFormOuter .table th {
    padding: 0.8rem 0.8rem;
}

.customFormOuter label {
    font-weight: bold;
    margin-bottom: 5px;
    font-size: 0.95rem;
}

.customFormOuter h4.page-title {
    color: #727cf5;
    font-size: 1.5rem;
}


.customFormInline {
    display: inline-flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 9px;
}

.customTitleHead.p-2.card {
    padding: 5px 10px !important;
    margin-bottom: 10px;
}

.customTitleHead h4.page-title {
    color: #727cf5;
    font-size: 1.5rem;
    line-height: 1.5;
    margin: 0;
}

.card-body {
    padding: 1rem;
}


body.sidebar-enable .side-nav .menuitem-active a.side-nav-link,
body.sidebar-enable .side-nav a.side-nav-link:hover,
body.sidebar-enable .side-nav a.side-nav-link {
    width: 70px !important;
}

/* body.sidebar-enable .side-nav .menuitem-active a.side-nav-link span{
    display: none !important;
} */

.side-nav li.side-nav-item {
    margin-bottom: 5px;
}

.side-nav>li.side-nav-item:nth-child(4),
.side-nav>li.side-nav-item:nth-child(7) {
    margin-bottom: 20px;
}

.side-nav>li.side-nav-item:nth-child(4)::after,
.side-nav>li.side-nav-item:nth-child(7)::after {
    content: "";
    background: #303A52;
    height: 2px;
    width: 85%;
    margin: 0 auto;
    display: block;
    position: relative;
    top: 10px;
}


.searchIconOuter {
    position: relative;
}

.searchIconOuter input {
    padding-right: 30px;
}

.searchIcon {
    position: absolute;
    top: 0px;
    right: 10px;
    font-size: 25px;
    color: #dee2e6;
}

.login_logo {
    position: absolute;
    top: 40px;
    left: 40px;
}

.login_page h2 {
    color: #000;
}

.login_page h4 {
    font-weight: 400;
    color: #000;
    margin-bottom: 20px;
}

.login_page label {
    color: #000;
    font-weight: 400;
    font-size: 17px;
}

.customCheck label {
    color: #727cf5;
    margin-bottom: 15px;
}

.backIcon {
    font-size: 30px;
    color: #727cf5;
}

.orDiv {
    text-align: center;
    margin: 25px 0;
    position: relative;
}

.orDiv span::before {
    content: "";
    background: #D2D2D2;
    height: 1px;
    width: 44%;
    position: absolute;
    left: 0;
    top: 10px;
}

.orDiv span::after {
    content: "";
    background: #D2D2D2;
    height: 1px;
    width: 44%;
    position: absolute;
    right: 0;
    top: 10px;
}

.logingoogle {
    border: 1px solid #d2d2d2;
    display: inherit;
    width: 100%;
    text-align: center;
    color: #000;
    padding: 7px 0;
    font-size: 16px;
    font-weight: 400;
    border-radius: 5px;
}

.dropzone {
    border-color: #727cf5;
}




.error-message {
    color: red;
    position: relative;
    top: -14px;
    margin: 0;
    font-size: 12px;
    left: -2px;
}

.error-message::before {
    content: "\e9b4";
    font-family: unicons;
    color: red;
    font-size: 15px;
    margin-right: 5px;
}

.error-message:empty {
    display: none;
}

body[data-leftbar-compact-mode=condensed]:not(.authentication-bg) .side-nav .side-nav-item .side-nav-link {
    width: 70px !important;
}


body[data-leftbar-compact-mode=condensed]:not(.authentication-bg) .side-nav .side-nav-item .side-nav-link:hover {
    width: 200px !important;
}

.side-nav-second-level li a i {
    font-size: 1.1rem;
}

.customTableClass .btn.btn-link {
    box-shadow: none;
    outline: none;
}


.button-menu-mobile .mdi-chevron-double-right {
    display: none;
}

body.sidebar-enable .mdi-chevron-double-left {
    display: none;
}

body.sidebar-enable .mdi-chevron-double-right {
    display: block;
}

.Toastify div {
    font-family: 'Nunito', sans-serif;
    color: #000;
    font-weight: 600;
}

.smIcon {
    max-height: 20px;
}

/* chat box design  css starts*/

.meesages-list {
    padding: 20px 30px;
    width: 100%;
    background: #fff;
    height: 550px !important;
    overflow-y: scroll;
}

.messageItem {
    width: 100%;
    float: left;
    text-align: left;
    margin-bottom: 15px;
    padding-left: 70px;
    position: relative;
}

.messageItem .innerMessage {
    background: #E7E8FF;
    display: inline-block;
    padding: 10px;
    position: relative;
    border-radius: 0 12px;
}

.messageItem .innerMessage::before {
    content: "";
    border-top: 0px solid transparent;
    border-right: 20px solid #E7E8FF;
    border-bottom: 15px solid transparent;
    position: absolute;
    left: -18px;
    top: 0;
    border-bottom-right-radius: 5px;
}

.firstLetter {
    width: 50px;
    height: 50px;
    background: #E7E8FF;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    border-radius: 50px;
    top: 0;
    left: 0;
    text-transform: uppercase;
    color: #727CF5;
    font-weight: 600;
}

.ownLetter {
    left: inherit;
    right: 0;
    background: #ECECEC;
    color: #434343;
}

.ownMessage {
    float: right;
    text-align: end;
    padding-left: 0;
    padding-right: 70px;
}

.messageItem.ownMessage .innerMessage {
    background: #ECECEC;
    display: inline-block;
    padding: 10px;
    border-radius: 12px 0px;
}

.messageItem.ownMessage .innerMessage::before {
    content: "";
    border-top: 0px solid transparent;
    border-left: 20px solid #ECECEC;
    border-bottom: 15px solid transparent;
    position: absolute;
    right: -18px;
    left: auto;
    top: 0;
    border-bottom-right-radius: 5px;
    border-right: inherit;
}


.messageItem.messageItem.notificationChatMessage {
    text-align: center;
}

.messageItem.notificationChatMessage .innerMessage {
    background: #f9e8c9;
}

.messageItem.notificationChatMessage .innerMessage::before {
    content: none;
}

.messageItem.notifytextMsg .innerMessage {
    text-align: center;
    background: #f5f5f5;
    padding: 2px 7px;
    border-radius: 5px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: auto;
}

.messageItem.notifytextMsg .innerMessage::before {
    content: none;
}

.messageItem.notifytextMsg span.messageTxt {
    margin: 0;
    font-size: 14px;
}

.chatBoxOuterHght {
    height: 400px;
    width: 100%;
}

.chatHeading {
    padding: 15px;
    border-bottom: 1px solid #ddd;
    width: 100%;
}


.chatHeading h3 {
    color: #727cf5;
    font-size: 25px;
    margin: 0;
}

.chats {
    padding: 15px;
}

.channel-list {
    background: rgba(114, 124, 245, 0.1);
}

.time {
    font-size: 13px;
    font-weight: 500;
}

.messageTxt {
    color: #000;
    font-size: 15px;
    margin: 5px 0;
    display: inline-block;
    text-align: left;
}

.sendBtn img {
    max-width: 12px;
    margin-top: -3px;
    margin-left: 2px;
}

.senderPerson {
    color: #777777;
    margin-bottom: 2px;
    font-weight: 500;
    font-size: 15px;
}

.messages-input input {
    background: rgba(114, 124, 245, 0.1);
    padding: 12px;
    color: #777777;
}

.messages-input input:focus {
    background: rgba(114, 124, 245, 0.1);
}

.messages-input input::placeholder {
    color: #777777;
}

/* width */
.meesages-list::-webkit-scrollbar {
    width: 5px;
}

/* Track */
.meesages-list::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

/* Handle */
.meesages-list::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 5px #727cf5;
    border-radius: 10px;
}

.channel-item.active {
    background: #fff;
    border-color: #fff;
    border-radius: 6px;
}

.removeicon .menu-arrow {
    display: none;
}

.removeicon .side-nav-second-level {
    margin-top: -5px !important;
}

.removeicon a.side-nav-link-ref.side-sub-nav-link,
.removeicon a.side-nav-link-ref.side-sub-nav-link.active {
    padding-right: 10px;
    width: 89%;
}

.removeicon a.side-nav-link-ref.side-sub-nav-link.active {
    margin-top: 5px;
}


.inner-tab .nav.nav-tabs {
    position: absolute;
    right: 0;
    border: 0;
}

.inner-tab .tab-content {
    margin-top: 20px;
}

.inner-tab .nav-tabs .nav-link {
    border: 0;
    color: #777777;
    font-size: 16px;
    padding-right: 0;
    margin-right: 15px;
    padding-left: 20px;
    position: relative;
}

.inner-tab .nav-tabs .nav-link.disabled {
    opacity: 0.6;
}

.inner-tab .nav-tabs .nav-link.active {
    color: #727CF5;
    font-weight: 700;
    border-bottom: 2px solid #727cf5;
}

.inner-tab .nav-tabs li .nav-link::before {
    font-family: "unicons";
    position: absolute;
    left: 0;
}


.inner-tab .nav-tabs li:nth-child(1) .nav-link::before {
    content: "\eaf2";
}

.inner-tab .nav-tabs li:nth-child(2) .nav-link::before {
    content: "\e94f";
}


.inner-tab .nav-tabs li:nth-child(3) .nav-link::before {
    content: "\f0255";
    font-family: 'Material Design Icons';
}


.inner-tab .nav-tabs li:nth-child(4) .nav-link::before {
    content: "\e8c8";
}

.inner-tab .nav-tabs li:nth-child(5) .nav-link::before {
    content: "\e9cd";
}


.inner-tab .nav-tabs li:nth-child(6) .nav-link::before {
    content: "\e93f";
}

.inner-tab .nav-tabs li:nth-child(7) .nav-link::before {
    content: "\eb9c";
}

.inner-tab .nav-tabs li:nth-child(8) .nav-link::before {
    content: "\e8bf";
}

.inner-tab .nav-tabs li:nth-child(9) .nav-link::before {
    content: "\eb6a";
}





/*======== dashboard css ========== */

.widgetHead {
    /* border-bottom: 1px solid #ddd;
    padding: 15px; */
    justify-content: space-between;
}

.dashHead {
    color: #000;
}

.barHead {
    position: absolute;
    top: 5px;
    left: 10px;
}

.brdBtm {
    border-bottom: 1px solid #ddd;
}

.widgetHead .widget-icon {
    border-radius: 50px;
    margin-right: 8px;
}

.iconHeadTxt {
    color: #000;
}

.widgetCount {
    width: 120px;
    text-align: center;
    padding: 2px 0 3px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
   
}
.orderRow .widgetCount:nth-child(even) {

    border-right: 1px solid #ddd;

    margin-right: 2px;

}

.orderRow .widgetCount:last-child:nth-child(even) {
   border: 0;
   margin: 0;
}

.widgetCount:last-child {
    border: 0;
}

.widgetCount .text-muted {
    color: #777 !important;
    font-size: 12px;
}

.widgetCount h5 {
    font-size: 14px;
    font-weight: 600;
}

.side-nav .badge {
    margin-top: 4px;
    float: right;
    background: #1A253D;
    color: #727CF5;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: inline-flex;
    align-items: center;
    text-align: center;
    padding: 0;
    justify-content: center;
}

.automationDiv {
    text-align: center;
    margin: 30px 0;
}

.autosecond {
    min-height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.automationDiv h2 {
    color: #727cf5;
}

.tickImg {
    max-width: 150px;
}

.gifImg {
    max-width: 250px;
    margin-top: -20px;
}

.skeletonTableImg {
    width: 100%;
    height: 260px;
    position: relative;
    z-index: 2;
}

.textGrey {
    color: #7f7f7f;
}

.automationDiv p {
    font-size: 18px;
}

.ReactModal__Overlay {
    z-index: 9999 !important;
}

.ril-image-current {
    z-index: 999 !important;
}

.h_200 {
    height: 235px;
}

/* custom notification toast */

.customNotifyToast {
    width: 420px;
    right: 95px;
    transition: all 0.4s ease-in-out;
}

/* .customNotifyToast .Toastify__toast-body {
    padding-left: 50px;
} */

/* .Toastify__toast-container::before {
    content: "";
    background: rgba(0, 0, 0, 0.1);
    display: block;
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
} */

.customNotifyToast .notifyToast {
    padding-left: 50px;
}

.customNotifyToast div {
    transition: all 0.4s ease-in-out;
}

.customNotifyToast .Toastify__toast-icon svg {
    display: none;
}

.customNotifyToast .Toastify__close-button {
    opacity: 1;
}

.customNotifyToast .Toastify__close-button>svg {
    color: red;
    opacity: 1;
}

.bell {
    max-width: 100px;
    position: absolute;
    left: -5px;
    z-index: -1;
}

.email {
    max-width: 150px;
}

.divsecond {
    display: none;
}

.expandToast .divfirst {
    display: none;
}

.expandToast .divsecond {
    display: block;
}

.customNotifyToast .notifyToast.expandToast {
    padding-left: 0px;
    text-align: center;
}


.Toasttxt h4 {
    color: #727cf5;
    margin-bottom: 5px;
}

.Toasttxt p {
    color: #777;
    font-size: 15px;
}

.customNotifyToast .solidBtn {
    background-color: #727cf5;
    border-color: #727cf5;
    color: #fff;
}

.customNotifyToast .solidBtn:hover {
    background-color: #6169d0;
    border-color: #6169d0;
    color: #fff;
}

.customNotifyToast .borderWhiteBtn {
    background-color: transparent;
    border-color: #ddd;
    color: #727cf5;
}

.customNotifyToast .borderWhiteBtn:hover {
    background-color: #727cf5;
    border-color: #727cf5;
    color: #fff;
}

.customNotifyToast .Toastify__progress-bar--success {
    /* display: none;
     */
    opacity: 0 !important;
}

/* =====admin dashboard css starts here==== */


.adminDashboard .card {
    margin-bottom: 10px;
}

.chartHeader {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
}

.teamChartRelative {
    position: relative;
}

.totalSize {
    position: absolute;
    top: 33px;
    left: 2px;
    right: 0px;
    margin: 0 auto;
    width: 100px;
    text-align: center;
}

.totalSize p {
    margin: 0;
}

.plusIcon {
    color: #727cf5;
    font-size: 35px;
    position: relative;
    left: -6px;
    line-height: 1;
}

.teamSize {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.teamSizeRow {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    width: 100%;
}

.teamCard {
    /* width: 25%; */
    display: flex;
    align-items: center;
}

.progressLine {
    width: 100%;
    height: 7px;
    display: inline-block;
    border-radius: 50px;
    margin-bottom: 5px;
}

.bg_purple {
    background: #727CF5;
}

.bg_yellow {
    background: #FFCF55;
}

.bg_green {
    background: #0EC970;
}

.bg_black {
    background: #0D1432;
}

.bg_red {
    background: #FF5050;
}

.bg_darkgry {
    background: #777777;
}

.w-15 {
    width: 15%;
}

.teamCard p {
    margin-bottom: 0;
}

.teamCard h3 {
    color: #000;
    margin-top: 0;
    font-weight: 600;
}

.bulletCircle {
    width: 10px;
    height: 10px;
    display: inline-block;
    border-radius: 50px;
    margin-right: 0;
    position: relative;
    top: -2px;
}

.chartSelectBox {
    position: absolute;
    right: 4px;
    top: 5px;
    width: 145px;
    padding-left: 5px;
    border: 0;
}

.funnelChart {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: flex-end;
}

.column_first,
.columnSecond {
    /* width: 40%; */
    text-align: center;
}

.columnSecond {
    width: 17%;
}

.columnThird {
    width: 30%;
}

.funnelChart ul {
    padding: 0;
    margin: 0;
    list-style: none;
    position: relative;
}

.column_first ul {
    width: 170px;
}


ul.funnel::before {
    content: "";
    width: 15px;
    height: 98%;
    display: block;
    position: absolute;
    left: 9px;
    background: #fff;
    transform: rotate(-15deg);
    top: 15px;
}

ul.funnel::after {
    content: "";
    width: 15px;
    height: 98%;
    display: block;
    position: absolute;
    right: 9px;
    background: #fff;
    transform: rotate(15deg);
    top: 15px;
}


.funnel li {
    padding: 10px;
    color: #fff;
    list-style: none;
    margin: 0 auto;
    height: 40px;
}

.funnel li:first-child {
    height: 60px;
}

.funnelBox1 {
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    width: 165px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.funnelBox2 {
    width: 140px;
}

.funnelBox3 {
    width: 120px;
}

/* .funnelBox4 {
    width: 110px;
} */

.bg_600 {
    background: #727CF5;
}

.bg_500 {
    background: #A7AEFF;
}

.bg_400 {
    background: #D2D5FF;
}

.bg_300 {
    background: #dddddd;
}


.middleBox {
    font-size: 13px;
    padding: 5px 10px;
    margin-bottom: 9px;
    text-align: center;
    width: 100%;
    color: #fff;
    border-radius: 6px;
}


.columnSecond li {
    list-style: none;
    padding: 10px;
    width: 100%;
    height: 40px;
}

.columnSecond img {
    width: 100%;
}

/* new funnel chart design */

.funnelNew ul {
    margin: 0;
    padding: 0;
    list-style: none;
}


.funnelNew li:nth-child(1) {
    width: 100%;
}

.funnelNew li:nth-child(2) {
    width: 85%;
    margin-top: -15px;
    z-index: 1;
}

.funnelNew li:nth-child(3) {
    width: 70%;
    margin-top: -15px;
    z-index: 0;
}

.funnelNew li:nth-child(2) .funnelContent {
    top: 8px;
}

.funnelNew li:nth-child(3) .funnelContent {
    top: 8px;
}

.funnelNew ul li {
    width: 100%;
    height: 80px;
    position: relative;
    margin: 0 auto;
}

.funnelNew ul li img {
    width: 100%;
    height: 100%;
}

.funnelContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
}

.funnelContent h5 {
    text-align: center;
    color: #fff;
    margin: 0;
    font-weight: 400;
}

.funnelValue {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 3px auto 0;
    width: 75%;
}

.funnelValue h6:first-child {
    margin-right: 10px;
}

.funnelValue h6 {
    color: #fff;
    margin: 0;
    font-weight: 400;
    font-size: 15px;
}

.totalOrders {
    border: 1px solid #ddd;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    border-radius: 10px;
    width: 100%;
}

.totalOrders h3 {
    margin: 0;
    color: #000;
}

.totalOrders p {
    margin: 0;
}

.worldwide table td {
    color: #000;
}

.worldwide table td:nth-child(2),
.worldwide table th:nth-child(2) {
    text-align: right;
}

.worldwide table td:nth-child(3),
.worldwide table th:nth-child(3) {
    text-align: right;
}

.adminFilters {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    align-items: center;
    margin-bottom: 10px;
}


.b-0 .css-13cymwt-control,
.b-0 .css-13cymwt-control:hover {
    border: 0 !important;
}

.container_P_0 .container {
    padding: 0;
}

.standard_file_upload button {
    position: relative;
    top: -100px;

}

.cstom_card_outer {
    border: 1px solid #eee;
    padding: 10px;
    box-shadow: 0 0 9px #eee;
    height: 100%;
}

.customFileApprovalUpload .customFormOuter {
    border: 1px solid #ddd;
    margin-top: 5px;
}

.customFileApprovalUpload .card-body form > div {
    width: 24.1%;
    float: left;
    margin-right: 6px;
}

.vendor_prfleDp {
    max-width: 100px;
}


/*! Satyam : CSS for customer dashboard donought chart */

/* .prj-dough {
    display: flex;
    padding: 0 0 4rem 1rem;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: wrap;
} */

.all-charts {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
}

.right_side_chart {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.single-dough {
    text-align: center;
    margin-right: 2rem;
}

.single-dough::before {
    content: "";
    position: absolute;
    top: 5px;
    height: 70%;
    border-right: 1px solid #D2D2D2;
    right: -15px;
}

.single-dough canvas {
    height: 140px;
    width: 140px;
}

.single-dough p {
    font-size: 2rem;
    line-height: 2;
}

.single-dough h6 {
    font-size: 1rem;
    font-weight: 700;
}

.small-font {
    font-size: 11px !important;
    color: #777777;
}

.chartDough {
    text-align: center;
    margin-right: 0.5rem;
}

.chartDough canvas {
    height: 110px;
    width: 110px;
}

.right_side_chart .chartDough:last-child {
    margin-right: 0;
}


.leftside-menu::after {
    content: "";
    width: 100%;
    position: absolute;
    bottom: 50px;
    left: 0;
    right: 0;
    height: 1px;
    background: #333;
}

.percentageFont {
    font-size: 1rem;
    font-weight: 700;
    line-height: 2.2;
}


.transp-btn {
    margin-bottom: 1rem;
}


/* .cust-toast {
    
} */

.choice-btn {
    display: flex;
    justify-content: space-between;
    /* background-color: #0EC970 !important; */
    width: 60% !important;

}

.choice-btn button {
    border: none;
    width: 70px;
    box-shadow: none;
    text-decoration: none;
    /* background-color: #966837; */
}


.choice-btn button:active {
    border: none;
    box-shadow: none;
    text-decoration: none;
    /* background-color: red; */
}

/*! sATYAM cUSTOMER CSS ENDS HERE */









/* ======== vendors signup form css ========== */

.vendorSignupMain {
    background: url(../images/vendorBg.webp);
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    background-position: center center;
    background-size: cover;
}

.Vendorlogo img {
    max-width: 270px;
}

.footer-links2 ul {
    display: flex;
    list-style: none;
    padding: 0;
}

.footer-links2 a {
    color: #fff;
    margin-right: 25px;
    font-size: 16px;
}

.copy-right2 p {
    color: #fff;
}

.vendorLeftSignup {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
    padding-left: 50px;
    padding-top: 50px;
    padding-bottom: 50px;
}

.vendorSignup {
    padding: 30px;
}

.vendorSignupForm {
    background: #fff;
    border-radius: 5px;
    height: 100%;
    padding: 25px;
    width: 100%;
}


.vendorSignupForm .nav.nav-tabs {
    border: 0;
}

.vendorSignupForm .tab-content {
    margin-top: 20px;
}

.vendorSignupForm .nav-tabs .nav-link {
    border: 0;
    color: #000;
    font-size: 17px;
    padding-right: 0;
    margin-right: 20px;
    padding-left: 20px;
    position: relative;
    background: transparent;
}

.vendorSignupForm .nav-tabs .nav-link.active {
    color: #727CF5;
    font-weight: 700;
    /* border-bottom: 2px solid #727cf5; */
}

.vendorSignupForm .nav-tabs li .nav-link::before {
    font-family: "unicons";
    position: absolute;
    left: 0;
}


.vendorSignupForm .nav-tabs li:nth-child(1) .nav-link::before {
    content: "\eb95";
}

.vendorSignupForm .nav-tabs li:nth-child(2) .nav-link::before {
    content: "\ebda";
}

.formNxtBtn {
    background: #4A4A4A;
    border-color: #4A4A4A;
}

/* .vendorSignupForm h2 , .vendorSignupForm p {
    color: #fff;
} */

/* .vendorSignup input[type=text]:active, .vendorSignup input[type=text]:focus , .vendorSignup .form-control {
    background: hsla(0,0%,100%,.1);
    box-shadow: none;
    color: #777;
    border: none;
    outline: none;
}

.vendorSignup .form-label  , .vendorSignup label {
  color: #fff;
} */

.stepCountTop {
    position: absolute;
    right: 45px;
    margin-top: -45px;
}

.stepCountTop span {
    color: #966837;
}

.successVendorMsg h3 {
    color: #966837;
}

.successVendorMsg i {
    font-size: 100px;
    line-height: 1;
    color: #966837;
}

.vendorSignupForm form p {
    margin-bottom: 0;
}

.btn.linkButton {
    position: relative;
    padding: 0;
    line-height: 1;
    background-color: transparent !important;
    border: 0;
    box-shadow: none !important;
    color: #966837 !important;
    font-weight: 700;
}

.btn.linkButton.danger {
    color: red !important;
}

.dflexspcBtwn {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
}

.gc-map .gmaps {
    position: fixed !important;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 9999;
    width: 100%;
    height: 100vh;
}

.noDataDiv {
    text-align: center;
    width: 100%;
    min-height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.noDataDiv img {
    max-width: 200px;
}

.noDataDiv h3 {
    color: #000;
    margin: -20px 0 0;
}

.noDataDiv p {
    color: #777;
    font-size: 18px;
    margin: 5px 0 10px;
}

.no-content-msg {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 500px;
    width: 100%;
}

.no-content-msg img {
    max-width: 350px;
}

.btn_grey {
    border: 1px solid #D9D9D9;
    color: #000;
    box-shadow: 0 0 10px #d9d9d9;
    background: transparent;
}

.btn_grey:hover {
    background-color: #D9D9D9;
    border: 1px solid #D9D9D9;
    color: #000;
}

.vendor_chat .col-xxl-9 {
    width: 100%;
}

.elipse {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 250px;
}

.width_5 {
    width: 45%;
    max-width: 45%;
}

.width_7 {
    width: 55%;
    max-width: 55%;
}

.text_purple {
    color: #727cf5;
}

.chat-cursor-link {
    cursor: pointer;
    word-break: break-all;
}

.progressLabel ul{
    margin: 0;
    padding: 0;
}

.progressLabel ul li {
    display: inline-block;
    list-style: none;
    margin-right: 10px;
}

.progressLabel ul li span {
    width: 12px;
    height: 12px;
    display: inline-block;
    margin-right: 5px;
    border-radius: 10px;
}

.ViewContainer {
    width: 100%;
    display: inline-flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 10px;
}

.ViewContainer select {
    width: 150px;
    margin: 10px 0;
}

._2dZTy:nth-child(even) {
   fill: #fff !important;
}

._34SS0:nth-of-type(even) {
    background-color: #fff !important; 
}

.action-icon i.sizesm {
    font-size: 16px;
    top: -1px;
    position: relative;
}

.chat-badge {
    position: absolute;
    top: -4px;
    right: -12px;
    background: red;
    color: white;
    font-size: 11px;
    border-radius: 10px;
    padding: 2px 6px;
}

.table .action-icon {
    position: relative;
}

.customerDashChat .col-xxl-9{
    width: 100%;
}

.customerNofity .table-responsive {
    height: 230px;
}


/* width */
.customerNofity .table-responsive::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

/* Track */
.customerNofity .table-responsive::-webkit-scrollbar-track {
box-shadow: inset 0 0 5px grey; 
border-radius: 10px;
}

/* Handle */
.customerNofity .table-responsive::-webkit-scrollbar-thumb {
background: #727CF5; 
border-radius: 10px;
}

/* Handle on hover */
.customerNofity .table-responsive::-webkit-scrollbar-thumb:hover {
background: #727CF5; 
}

/* .customerDashChat .position-relative.card-body > div {
    height: auto !important;
} */

.customerDashChat .inputChatboxMain {
    padding: 5px !important;
}

.customerDashChat .inputChatboxMain .btn {
    padding: 5px !important;
}

.customerDashChat .chatTypeInput {
    padding: 5px 4px;
    height: 45px;
}

.customerDashChat .chatBoxOuterHght {
    height: 330px;
}

.conversation-list .ctext-wrap {
    background: #f3f3f3;
    border-radius: 8px;
    padding: 5px 10px;
    min-width: 200px;
}

.conversation-list .ctext-wrap:after {
    content: none;
}

.senderName {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.bg-gry {
    background: #f5f5f5;
    border: 0px !important;
}
.conversation-list .odd .ctext-wrap {
    background-color: #727CF5;
    color: #fff;
}

.chatTypeInput {
    height: 55px;
    border-radius: 10px;
}

.userTypList {
    border-bottom:1px solid #ddd;
}

.chatSearch input {
    width: 100%;
    position: relative;
    padding-left: 28px;
    margin-bottom: 15px;
    background: #FAFAFA;
    border-color: #f5f5f5;
}

.chatSearch img {
    position: absolute;
    top: 8px;
    width: 20px;
    left: 17px;
}

.chatFileUpload {
    background: #eee;
    padding: 5px;
    border-radius: 8px;
    margin: 5px 0;
}

.chatFileUpload i {
    font-size: 18px !important;
}

.conversation-list .ctext-wrap i , .conversation-list .odd .ctext-wrap i{
    color: #333333;
}

.odd .senderName h6.me-2 {
    margin-right: 0 !important;
}

.conversation-text .conversation-text {
    margin-left: 0;
    margin-right: 24px !important;
}

.vendorHoverDetails {
    color: #000;
    margin-bottom: 4px;
    opacity: 1;
    font-family: 'Nunito';
}

.vendorHoverDetails span {
    font-weight: 600;
    margin-right: 2px;
}

.vendorHoverBox {
    position: relative;
    padding-right: 50px;
    padding-bottom: 5px;
}

.vendorHoverBox img {
    max-width: 40px;
    position: absolute;
    right: 0;
    top: 0;
    object-fit: contain;
    background: #eee;
    padding: 4px;
    border-radius: 5px;
}

.vendorHoverBox h5 {
    color: #000;
    font-weight: 500;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 200px;
    margin: 5px 0;
    font-family: 'Nunito';
}

.vendorHoverBox ol {
    padding-left: 15px;
    margin-bottom: 5px;
}

.vendorHoverBox p{
    font-family: 'Nunito';
}

.vendorHoverBox ul li {
    list-style: decimal;
    padding-left: 3px;
    margin-bottom: 1px;
    font-family: 'Nunito';
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 200px;
}

.cstmVndrBtn {
    background: #727cf5;
    color: #fff;
    box-shadow: none;
    border: 0;
    padding: 5px 8px;
    border-radius: 3px;
    margin-bottom: 5px;
}

.gm-style .gm-style-iw-c {
    box-shadow: none;
    max-height: 170px !important;
}

.gm-style-iw-d {
    max-height: 170px !important;
}

.notifyeclipse {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 230px;
    white-space: nowrap;
    margin-bottom: 2px !important;
}

.customerDshbrdHeading .card-body {
    padding: 0;
}

.customerDshbrdHeading .card {
    box-shadow: none;
    margin: 0;
    height: 150px;
    overflow: auto;
}

.vendorDashbd.customerDshbrdHeading .card {
    height: 210px;
}

.vendorfilesDashbd.customerDshbrdHeading .card {
    height: 345px;
}

.vendor_chat .chatBoxOuterHght{
    height: 370px;
}

.customerDshbrdHeading .customPagination {
    display: none;
}

.fntsmall {
    font-size: 13px;
}

.toggleSwitch {
    position: absolute;
    top: 10px;
    right: 15px;
}

.search_bar {
    position: relative;
}

.search_bar input {
    padding-left: 30px;
}

.search_bar i {
    position: absolute;
    left: 7px;
    top: 3px;
    font-size: 22px;
    color: #d2d2d2;
}


/* custom-tree.css */

#treeWrapper, .rd3t-tree-container {
    width: 100%;
    min-height: 80vh;
    height: 80vh !important;
}

/* #treeWrapper svg > g {
   
} */


.node__root > circle {
    fill: #727cf5;
}
  
.node__branch > circle {
    fill: #727cf5;
}
  
.node__leaf > circle {
    fill: #727cf5;
    /* Let's also make the radius of leaf nodes larger */
}

.cheque_image{
   max-width: 200px;
}

.cursor-pointer {
    cursor: pointer;
}

.gant-custom-outer svg text {
   fill: #000 !important;
}

.cheque-img {
    max-width: 200px;
}

.cursor-pointer-option{
    cursor: pointer;
}

.iot-iframe {
    width: 100%;
    height: 100vh;
}

/* google map */
.map-backbutton {
    z-index: 9999;
    position: absolute;
    left: 10px;
    top: 60px;
    background: #fff;
    box-shadow: 0 0 15px #ddd;
    padding: 5px 10px;
    border: 1px solid #ddd;
    border-radius: 3px;
    color: #000;
    font-weight: 600;
    font-size: 16px;
}

.mr-2 {
    margin-right: 20px;
}

.gant_header {
    border-top: 1px solid #eee;
}

.gant_header, .gant_tr_row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 50px;
    border-bottom: 1px solid #eee;
}

.gant_td {
    height: 50px;
    border-left: 1px solid #eee;
    padding: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    min-width: 90px;
}

.project_name_td {
    min-width: 170px;
    text-align: left;
}

/* ===================Style To Put Action Button Allign with Tab Header In Project and Enquiry=================== */

.inner-tab .innerbuttonOuter {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

/* ===================Style To Put Action Button Allign with Tab Header In Project and Enquiry=================== */



/* .customFormOuter .form-check-input.form-control {
    padding: 0;
} */

/*===================================responsive start here================================================*/

/* For 4K Displays (Ultra HD) */
@media screen and (min-width: 3840px) {}

/* For Projectors or Higher Resolution Screens (Full HD) */
@media screen and (min-width: 1920px) {}

/*============================= Tablet landscape size to standard 960  =============================*/
@media only screen and (min-width: 959px) and (max-width: 1024px) {}

/* ============================= global tablet and mobile =============================*/
@media only screen and (max-width: 959px) {

    .vendorLeftSignup {
        padding: 20px;
        text-align: center;
        justify-content: flex-start;
        height: 100%;
        min-height: 100%;
    }


    .vendorLeftSignup .footer-links2 {
        display: none;
    }

    .vendorSignup {
        padding: 10px;
    }

    .vendorSignupForm {
        padding: 15px;
    }

    .stepCountTop {
        margin-top: -25px;
    }



}


/* ================Tablet Portrait size to standard 960 (devices and browsers) ================*/
@media only screen and (min-width: 768px) and (max-width: 959px) {}

/* ==================All Mobile Sizes (devices and browser) ==================*/

@media only screen and (max-width: 767px) {

    .card-body {
        flex: 1 1 auto;
        padding: 0.5rem 0.5rem;
    }


    .leftside-menu,
    body.sidebar-enable .side-nav .menuitem-active a.side-nav-link,
    body.sidebar-enable .side-nav a.side-nav-link:hover,
    body.sidebar-enable .side-nav a.side-nav-link {
        width: 190px !important;
    }

    .side-nav .side-nav-link {
        padding: 10px;
    }

    .side-nav-second-level li a {
        padding: 3px 10px 3px 20px;
    }

    body.sidebar-enable .navbar-custom {
        left: 190px !important;
    }

    .logo.text-center {
        text-align: left !important;
        padding-left: 5px;
    }

    .logo .logo-lg img {
        height: 45px !important;
    }






}

/* ===================Mobile Landscape Size to Tablet Portrait (devices and browsers)=================== */
@media only screen and (min-width: 480px) and (max-width: 767px) {}

/* =====================Mobile Portrait Size to Mobile Landscape Size (devices and browsers)===================== */

@media screen and (max-width: 479px) {}