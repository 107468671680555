.new_fileupload {
    /* background-color: red; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 9rem;
    padding: 0 0 1rem 0;
    border: 2px dashed #D2D2D2;
}

.file-container {
    /* background-color: aqua; */
    display: flex;
}

.image-container {
    /* background-color: #FF5050; */

    margin: 0 2rem 0 2rem !important;

}

.remove-btn {
    background: none !important;
    box-shadow: none;
    border: none;
    color: #FF5050 !important;
    font-weight: 600;

}

.drop-file {
    /* background-color: #FF5050; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

.drop-file h5 {
    /* background-color: blueviolet; */
    line-height: 0.7;
    opacity: 70%;
}

.drop-file h6 {
    opacity: 50%;
}

.drop-file img {
    margin-top: 1.5rem;
}



.test {
    background-color: #ffffff;
    border: none;
    box-shadow: none;
    color: #727CF5;
}